$content-calendar-event-tile-caption-border: 1px solid color(border);
$content-calendar-event-tile-caption-padding: rem-calc(15 15 27);
$content-calendar-event-tile-caption-padding--large: rem-calc(28 28 35);

// Title
$content-calendar-event-tile-title-margin: rem-calc(0 0 10);
$content-calendar-event-tile-title-font: normal #{rem-calc(14)} / 1.45 $font-primary;
$content-calendar-event-tile-title-font--large: normal #{rem-calc(18)} / 1.3 $font-primary;
$content-calendar-event-tile-title-color: color(dark);
$content-calendar-event-tile-title-text-transform: lowercase;

// Description
$content-calendar-event-tile-description-font: 300 #{rem-calc(12)} / 1.5 $font-primary;
$content-calendar-event-tile-description-font--large: 300 #{rem-calc(14)} / 1.45 $font-primary;
$content-calendar-event-tile-description-color: color(dark);
$content-calendar-event-tile-description-color--large: color(text-secondary);
$content-calendar-event-tile-description-text-transform: lowercase;

@import "@lora/05-components/content/content-calendar-event-tile";