$publishing-info-date-text-transform: lowercase;
$publishing-info-date-text-transform--large: lowercase;
$publishing-info-date-font: 300 #{rem-calc(12)} / 1.4 $font-primary;
$publishing-info-date-font--large: $publishing-info-date-font;
$publishing-info-author-text-transform: lowercase;
$publishing-info-author-text-transform--large: lowercase;
$publishing-info-author-link-text-transform: lowercase;
$publishing-info-author-link-text-transform--large: lowercase;
$publishing-info-author-font: 300 #{rem-calc(12)} / 1.4 $font-primary;
$publishing-info-author-font--large: $publishing-info-author-font;
$publishing-info-author-link-font: 300 #{rem-calc(12)} / 1.4 $font-primary;
$publishing-info-author-link-font--large: $publishing-info-author-link-font;
$publishing-info-image-content-flex-direction: column-reverse;
$publishing-info-author-link-text-decoration: none;
$publishing-info-image-border-radius: 0;

@import "@lora/05-components/publishing-info";