@import "@root/05-components/carousel/carousel-content";

$carousel-content-ranking-position: null !default;
$carousel-content-ranking-color: null !default;
$carousel-content-ranking-top: null !default;
$carousel-content-ranking-z-index: null !default;
$carousel-content-ranking-font-size: null !default;

@mixin jplayer-components-carousel-ranking {
    .product-ranking-number {
        position: $carousel-content-ranking-position;
        color: $carousel-content-ranking-color;
        top: $carousel-content-ranking-top;
        z-index: $carousel-content-ranking-z-index;
        font-size: $carousel-content-ranking-font-size;
    }
}
