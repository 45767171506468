// Image
$figure-image-border: 6px solid transparent;

// Active state
$figure-active-image-border: 1px solid color(primary-active);
$figure-active-title-color: color(dark);
$figure-active-image-padding: rem-calc(5);

// Title
$figure-title-font: normal #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$figure-title-text-decoration: underline;
$figure-title-text-transform: lowercase;

@import "@lora/05-components/figure";